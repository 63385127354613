import { NgModule } from '@angular/core';
import { MenuVerticalService } from '@app/shared/components/menu-vertical/menu-vertical.service';

import { MenuVerticalComponent } from '@app/shared/components/menu-vertical/menu-vertical.component';
import { MenuVerticalTriggerComponent } from './menu-vertical-trigger/menu-vertical-trigger.component';
import {
  MenuVerticalItemAnchorComponent,
  MenuVerticalItemButtonComponent,
} from '@app/shared/components/menu-vertical/menu-vertical-item/menu-vertical-item.component';
import { MenuVerticalSectionComponent } from '@app/shared/components/menu-vertical/menu-vertical-section/menu-vertical-section.component';

@NgModule({
  imports: [
    MenuVerticalComponent,
    MenuVerticalTriggerComponent,
    MenuVerticalItemAnchorComponent,
    MenuVerticalItemButtonComponent,
    MenuVerticalSectionComponent,
  ],
  exports: [
    MenuVerticalComponent,
    MenuVerticalTriggerComponent,
    MenuVerticalItemAnchorComponent,
    MenuVerticalItemButtonComponent,
    MenuVerticalSectionComponent,
  ],
  providers: [MenuVerticalService],
})
export class MenuVerticalModule {}
