<app-base-modal [modalId]="modalId" [modalTitle]="'balances' | translate">
  <ng-container modal-header>
    <app-search
      [searchPlaceholder]="'quick-trade.main.search' | translate"
      (searchTermChange)="onSearchTermChange($event)"
    ></app-search>
  </ng-container>
  <ng-container modal-body>
    <table class="mt-2 table-auto w-full font-sans">
      <thead>
        <tr>
          <th
            *ngFor="let header of headers"
            class="py-2 text-[14px] leading-[20px] text-cm-grey-400 dark:text-cm-grey-200 font-normal text-right first:text-left"
          >
            {{ header | translate }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let currency of filteredCurrencyBalances">
          <td class="py-4 whitespace-nowrap">
            <div class="flex items-center">
              <img
                class="h-10 w-10 rounded-full"
                [src]="'/assets/crypto/color/' + currency.name.toLowerCase() + '.svg'"
                alt="Currency logo"
              />
              <div class="ml-4">
                <div
                  class="text-[14px] lg:text-[16px] leading-[20px] font-semibold text-cm-blue-900 dark:text-app-dark-mode-white"
                >
                  {{ getCurrencyDisplayName(currency.name) }}
                </div>
                <div class="text-[14px] leading-[20px] font-medium text-cm-grey-400 dark:text-cm-grey-200">
                  {{ currency.name }}
                </div>
              </div>
            </div>
          </td>
          <td class="py-4 whitespace-nowrap text-sm text-right">
            <div
              class="sm:block text-[14px] sm:text-[16px] leading-[20px] font-semibold text-cm-blue-900 dark:text-app-dark-mode-white"
              [ngClass]="{ 'sm:hidden': currency.name !== 'CZK' && currency.name !== 'EUR' }"
            >
              {{ currency.available | appCurrencyFloor: currency.name | async }} {{ currency.name }}
            </div>
            <div
              class="hidden sm:block text-[14px] leading-[20px] font-medium text-cm-grey-400 dark:text-cm-grey-200"
              [ngClass]="{ 'sm:block': currency.name !== 'CZK' && currency.name !== 'EUR' }"
            >
              {{ currency.fiatEquivalentsAvailableCZK | appCurrencyFloor: 'CZK' | async }} CZK ≈
              <span
                class="text-[14px] lg:text-[16px] leading-[20px] font-semibold text-cm-blue-900 dark:text-app-dark-mode-white"
                >{{ currency.available | appCurrencyFloor: currency.name | async }} {{ currency.name }}</span
              >
            </div>
            <div
              *ngIf="currency.name !== 'CZK' && currency.name !== 'EUR'"
              class="text-[12px] sm:text-[14px] leading-[20px] font-medium text-cm-grey-400 dark:text-cm-grey-200"
            >
              <ng-container *ngIf="currency.lastPriceCZK as lastPriceCZK">
                {{ lastPriceCZK | appCurrencyFloor: 'CZK' | async }} CZK
              </ng-container>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </ng-container>
</app-base-modal>
