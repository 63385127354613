<app-base-modal
  [modalId]="modalId"
  [modalTitle]="'shared.common.confirm' | translate"
  [modalSize]="'small'"
  [modalHiddenTitle]="true"
  (closeModal)="onClose()"
>
  <ng-container modal-header-icon>
    <svg-icon [src]="'/assets/icons/icon-info.svg'"></svg-icon>
  </ng-container>
  <ng-container modal-body>
    <div class="flex flex-col justify-between h-full gap-4 pt-4">
      <div *ngIf="modalData.modalBody" class="lg:text-large" [innerHTML]="modalData.modalBody"></div>

      <div class="flex flex-1 items-end justify-end gap-2 pt-9 pb-5">
        <app-action-button
          [label]="modalData.cancelLabel || 'shared.common.cancel'"
          [size]="'small'"
          [facet]="'secondary'"
          (click)="onCancel()"
        >
        </app-action-button>
        <app-action-button
          [label]="modalData.confirmLabel || 'shared.common.confirm'"
          [size]="'small'"
          [facet]="'danger'"
          (click)="onConfirm()"
        >
        </app-action-button>
      </div>
    </div>
  </ng-container>
</app-base-modal>
