import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { AbstractComponent } from '@app-shared/components/form/abstract/abstract.component';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectComponent),
      multi: true,
    },
  ],
})
export class SelectComponent extends AbstractComponent implements OnInit {
  @Input() placeholder?: string;

  constructor() {
    super();
  }

  ngOnInit(): void {}
}
