import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { combineLatest, EMPTY } from 'rxjs';
import { CurrencyPairsControllerService } from '@app-generated/services/currency-pairs-controller.service';
import {
  loadCurrencyPairs,
  loadCurrencyPairsWithStats,
  loadCurrencyPairsWithStatsWs,
  loadFavouriteCurrencyPairs,
  setCurrencyPairs,
  setCurrencyPairsWithStats,
  setFavouriteCurrencyPairs,
  updateCurrencyPairWithStats,
} from '@app-shared/store/currency-pairs/currency-pairs.actions';
import { UserCurrencyPairFavouritesControllerService } from '@app-generated/services/user-currency-pair-favourites-controller.service';
import { Injectable } from '@angular/core';
import { PushpinService } from '@app-shared/services/pushpin.service';

@Injectable()
export class CurrencyPairsEffects {
  loadCurrencyPairsWithStats$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadCurrencyPairsWithStats),
      mergeMap(() =>
        this.currencyPairsController.getCurrencyPairsWithStatsUsingGet().pipe(
          map((currencyPairsWithStats) => setCurrencyPairsWithStats({ currencyPairsWithStats })),
          catchError(() => EMPTY),
        ),
      ),
    ),
  );

  loadCurrencyPairsWithStatsWs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadCurrencyPairsWithStatsWs),
      mergeMap(() =>
        combineLatest([
          this.currencyPairsController.getCurrencyPairsWithStatsUsingGet(),
          this.pushpinService.getCurrencyPairWithStats$(),
        ]).pipe(
          map(([_currencyPairsWithStats, currencyPairWithStats]) =>
            updateCurrencyPairWithStats({ currencyPairWithStats }),
          ),
          catchError(() => EMPTY),
        ),
      ),
    ),
  );

  loadCurrencyPairs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadCurrencyPairs),
      mergeMap(() =>
        this.currencyPairsController.getCurrencyPairsUsingGet().pipe(
          map((currencyPairs) => setCurrencyPairs({ currencyPairs })),
          catchError(() => EMPTY),
        ),
      ),
    ),
  );

  loadFavouriteCurrencyPairs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadFavouriteCurrencyPairs),
      mergeMap(() =>
        this.favouritePairsController.getFavouritesUsingGet().pipe(
          map((favouriteCurrencyPairs) => setFavouriteCurrencyPairs({ favouriteCurrencyPairs })),
          catchError(() => EMPTY),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private currencyPairsController: CurrencyPairsControllerService,
    private favouritePairsController: UserCurrencyPairFavouritesControllerService,
    private pushpinService: PushpinService,
  ) {}
}
