import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable()
export class DropdownService implements OnDestroy {
  private openDropdownIdSubject = new BehaviorSubject<string | null>(null);

  protected readonly unsubscribe$ = new Subject<void>();

  openDropdownId$: Observable<string | null> = this.openDropdownIdSubject.asObservable();

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  openDropdown(id: string) {
    this.openDropdownIdSubject.next(id);
  }

  closeDropdown() {
    this.openDropdownIdSubject.next(null);
  }
}
