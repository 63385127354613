<a
  [routerLink]="link"
  [routerLinkActive]="'active'"
  class="link"
  [ngClass]="{ 'has-outline': hasOutline }"
  (click)="toggleSubMenu($event)"
>
  <svg-icon *ngIf="icon" [src]="icon"></svg-icon>
  <ng-content></ng-content>
  <ng-container *ngIf="children?.length">
    <svg-icon
      class="inline-block ml-2"
      [svgStyle]="{ 'width.px': 12, 'height.px': 7 }"
      src="/assets/icons/arrow-down.svg"
      *ngIf="!showSubMenu"
    ></svg-icon>
    <svg-icon
      class="inline-block ml-2"
      [svgStyle]="{ 'width.px': 12, 'height.px': 7 }"
      src="/assets/icons/arrow-up.svg"
      *ngIf="showSubMenu"
    ></svg-icon>
  </ng-container>
</a>
<ul class="submenu" *ngIf="showSubMenu">
  <li *ngFor="let subLink of children">
    <ng-container *ngIf="subLink.url?.startsWith('http'); else internalSubLink">
      <a
        class="submenu-link"
        [href]="subLink.url"
        [ngClass]="router.url.endsWith(subLink.url || '') ? 'active' : ''"
        (click)="toggleMenu()"
      >
        {{ subLink.name | translate }}
      </a>
    </ng-container>

    <ng-template #internalSubLink>
      <a
        class="submenu-link"
        [routerLink]="[subLink.url | localize]"
        [ngClass]="router.url.endsWith(subLink.url || '') ? 'active' : ''"
        (click)="toggleMenu()"
      >
        {{ subLink.name | translate }}
      </a>
    </ng-template>
  </li>
</ul>
