<div class="data-table" [class.data-table-has-sticky-columns]="showStickyShadow">
  <div class="data-table-head" [style.margin-right.px]="viewPortScrollbarWidth" #headerContainer>
    <div class="data-table-row" [style.width.px]="bodyContainerWidth">
      <ng-container *ngFor="let headerTemplate of headerTemplates; let i = index">
        <div
          class="data-table-cell"
          [style.min-width.px]="columnWidths[i]"
          [style.max-width.px]="columnWidths[i]"
          [style.right.px]="stickyColumnLeftPositions[i]"
          [class.data-table-cell-sticky]="stickyColumnLeftPositions[i] !== null"
        >
          <ng-container *ngTemplateOutlet="headerTemplate.template"></ng-container>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="data-table-cell-sticky-shadow"></div>
  <cdk-virtual-scroll-viewport
    class="data-table-virtual-scroll-viewport"
    (scrolledToBottom)="scrolledToBottom.emit()"
    [itemSize]="minRowHeight"
    autosize
    appScrolledToBottom
  >
    <div class="data-table-body" #bodyContainer>
      <div
        *cdkVirtualFor="let row of rows; trackBy: trackByItemId; let i = index"
        [style.min-height.px]="minRowHeight"
        class="data-table-row"
      >
        <ng-container *ngFor="let columnTemplate of columnTemplates; let i = index">
          <ng-container *ngTemplateOutlet="table; context: { $implicit: row, index: i, columnTemplate }"></ng-container>
        </ng-container>
      </div>

      <ng-container *ngIf="!rows?.length && loading; else noData">
        <div class="data-table-row">
          <ng-container *ngFor="let columnTemplate of columnTemplates; let i = index">
            <ng-container *ngTemplateOutlet="table; context: { index: i, columnTemplate }"></ng-container>
          </ng-container>
        </div>
      </ng-container>

      <ng-template #table let-row let-index="index" let-columnTemplate="columnTemplate">
        <div
          class="data-table-cell"
          [style.min-width.px]="columnWidths[index]"
          [style.max-width.px]="columnWidths[index]"
          [style.right.px]="stickyColumnLeftPositions[index]"
          [class.data-table-cell-sticky]="stickyColumnLeftPositions[index] !== null"
          [class.data-table-cell-auto-hide]="columnTemplate.autoHide"
        >
          <div class="data-table-cell-inner">
            <ng-container *ngIf="loading; else column">
              <app-skeleton [variant]="'text'"></app-skeleton>
            </ng-container>
            <ng-template #column>
              <ng-container *ngTemplateOutlet="columnTemplate.template; context: { $implicit: row }"></ng-container>
            </ng-template>
          </div>
        </div>
      </ng-template>

      <ng-template #noData>
        <div *ngIf="!rows?.length" class="data-table-empty">
          {{ emptyMessage }}
        </div>
      </ng-template>
    </div>
  </cdk-virtual-scroll-viewport>
</div>
