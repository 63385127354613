import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { SharedState } from '@app-shared/store/shared.reducer';
import { loadBalances, stopUpdating } from '@app/shared/store/balances/balances.actions';
import { queryCurrencyBalancesWithEquivalents } from '@app/shared/store/balances/balances.selectors';
import { loadCurrencyPairsWithStats } from '@app/shared/store/currency-pairs/currency-pairs.actions';

@Injectable({
  providedIn: 'root',
})
export class Balances {
  currencyBalancesWithEquivalents$ = this.store.select(queryCurrencyBalancesWithEquivalents());

  constructor(private store: Store<SharedState>) {}

  load() {
    this.store.dispatch(loadCurrencyPairsWithStats());
    this.store.dispatch(loadBalances());
  }

  stopUpdating() {
    this.store.dispatch(stopUpdating());
  }
}
