<div class="relative">
  <input
    type="text"
    class="w-full border rounded-md p-2 pl-10"
    placeholder="{{ searchPlaceholder }}"
    [(ngModel)]="searchTerm"
    (input)="onSearchTermChange($event)"
  />
  <svg-icon src="/assets/icons/icon-modal-search.svg" class="absolute left-2 top-2"></svg-icon>
</div>
