import { Component, Input, OnInit } from '@angular/core';
import { CurrencyDisplayName } from '@app/authenticated/quick-trade/quick-trade.enum';
import { CurrencyBalance } from '@app/shared/components/bar/context-currency-bar/context-currency-bar.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-context-currency-modal',
  templateUrl: './context-currency-modal.component.html',
  styleUrls: ['./context-currency-modal.component.scss'],
})
export class ContextCurrencyModalComponent implements OnInit {
  @Input() modalId = '';
  @Input() modalData: {
    balances: CurrencyBalance[];
  } = {
    balances: [],
  };

  filteredCurrencyBalances: CurrencyBalance[] = [];
  headers = ['currencies.table.header.currency', 'balance-price'];

  constructor(private translate: TranslateService) {}

  ngOnInit() {
    // TODO: Move the logic from /app/shared/components/bar/context-currency-bar/context-currency-bar.component.ts
    this.filteredCurrencyBalances = this.modalData.balances;
  }

  onSearchTermChange(searchTerm: string) {
    const lowerCaseSearchTerm = searchTerm.toLowerCase();
    this.filteredCurrencyBalances = this.modalData.balances.filter((currency) => {
      return (
        currency.name.toLowerCase().includes(lowerCaseSearchTerm) ||
        this.getCurrencyDisplayName(currency.name)
          // next two lines are for diacritics insensitive search
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .toLowerCase()
          .includes(lowerCaseSearchTerm) ||
        this.getCurrencyDisplayName(currency.name).toLowerCase().includes(lowerCaseSearchTerm)
      );
    });
  }

  getCurrencyDisplayName(currencyCode: string): string {
    const displayName = CurrencyDisplayName[currencyCode as keyof typeof CurrencyDisplayName];
    return displayName === 'crypto.CZK' ? this.translate.instant(displayName) : displayName;
  }
}
