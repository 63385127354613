import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ModalService } from '@app/shared/components/modal/modal.service';
import { Subscription } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-base-modal',
  templateUrl: './base-modal.component.html',
  styleUrls: ['./base-modal.component.scss'],
})
export class BaseModalComponent implements OnInit, OnDestroy {
  @Input() modalId = '';
  @Input() modalTitle = '';
  @Input() modalSize: 'large' | 'small' = 'large';
  @Input() modalClass = '';
  @Input() modalDisableClose = false;
  @Input() modalHiddenTitle = false;
  @Output() openModal = new EventEmitter<void>();
  @Output() closeModal = new EventEmitter<void>();
  isOpen = false;
  isDynamicModal = false;
  private subscription = Subscription.EMPTY;

  @ViewChild('modalContainer') modalContainerRef!: ElementRef;

  constructor(
    private cdr: ChangeDetectorRef,
    private modalService: ModalService,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  ngOnInit(): void {
    this.isDynamicModal = this.modalService.isDynamicModal(this.modalId);

    if (this.isDynamicModal) {
      const modalState$ = this.modalService.getDynamicModalState(this.modalId).pipe(
        map((modalState) => {
          return modalState.show && !modalState?.minimized;
        }),
      );

      this.subscription = modalState$.subscribe((show) => {
        this.isOpen = show;
        this.toggleModal();
      });
    } else {
      const modalState$ = this.modalService.getModalState(this.modalId);

      this.subscription = modalState$.subscribe((show) => {
        this.isOpen = show;
        this.document.body.style.overflow = this.isOpen ? 'hidden' : '';
        this.document.documentElement.style.overflowX = this.isOpen ? 'unset' : 'hidden';
        this.toggleModal();
      });
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  clickOutside(event: Event) {
    if (this.modalContainerRef.nativeElement === event.target) {
      this.close();
    }
  }

  close() {
    if (this.modalDisableClose) {
      return;
    }

    if (this.isDynamicModal) {
      this.modalService.closeDynamicModal(this.modalId);
    } else {
      this.modalService.closeModal(this.modalId);
    }
  }

  private toggleModal() {
    this.cdr.detectChanges();
    if (this.isOpen) {
      this.openModal.emit();
    } else {
      this.closeModal.emit();
    }
  }
}
