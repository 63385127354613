import { Directive, ElementRef, Input, OnInit } from '@angular/core';

import { DropdownPopoverService } from '@app/shared/components/dropdown/dropdown-popover.service';

@Directive({
  selector: 'button[dropdown-trigger]',
  standalone: true,
  host: {
    '(click)': 'togglePopover()',
  },
})
export class DropdownTriggerDirective implements OnInit {
  @Input() facet?: 'link';
  @Input() withChevron?: boolean;

  constructor(private element: ElementRef, private popoverService: DropdownPopoverService) {}

  ngOnInit() {
    const classes = ['dropdown-trigger'];

    if (this.facet) {
      classes.push(`dropdown-trigger-${this.facet}`);
    }

    if (this.withChevron) {
      classes.push('dropdown-trigger-with-chevron');
    }

    classes.forEach((className) => {
      this.element.nativeElement.classList.add(className);
    });
  }

  togglePopover() {
    this.popoverService.toggle();
  }
}

@Directive({
  selector: 'app-action-button[dropdown-trigger]',
  standalone: true,
  host: {
    '(click)': 'togglePopover()',
  },
})
export class DropdownTriggerActionButtonDirective implements OnInit {
  constructor(private element: ElementRef, private popoverService: DropdownPopoverService) {}

  ngOnInit() {
    const buttonElement = this.element.nativeElement.querySelector('button');

    if (buttonElement) {
      buttonElement.classList.add('dropdown-trigger');
    }
  }

  togglePopover() {
    this.popoverService.toggle();
  }
}
