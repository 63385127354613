<div
  class="dropdown {{ dropdownClass }}"
  [ngClass]="{
    'dropdown-popover-position-top-start': popoverPosition === popoverPositionEnum.topStart,
    'dropdown-popover-position-top-end': popoverPosition === popoverPositionEnum.topEnd,
    'dropdown-popover-position-bottom-start': popoverPosition === popoverPositionEnum.bottomStart,
    'dropdown-popover-position-bottom-end': popoverPosition === popoverPositionEnum.bottomEnd
  }"
  #dropdown
>
  <ng-content select="[dropdown-trigger]"></ng-content>
  <div *ngIf="visible" class="dropdown-popover-container" #dropdownPopoverContainer>
    <div class="dropdown-popover {{ popoverClass }}">
      <ng-content select="[dropdown-item]"></ng-content>
      <ng-content></ng-content>
    </div>
  </div>
</div>
