import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CurrencyPairWithStatsDto } from '@app/generated/models/currency-pair-with-stats-dto';
import { CurrencyDisplayName } from '@app/authenticated/quick-trade/quick-trade.enum';
import { TranslateService } from '@ngx-translate/core';
import { MultiChoiceButtonBarComponent } from '../../multi-choice-button-bar/multi-choice-button-bar.component';
import { CurrencyPairDto } from '@app/generated/models/currency-pair-dto';
import { map, takeUntil } from 'rxjs/operators';
import { combineLatest, Subject } from 'rxjs';
import { CurrencyPairs } from '@app/shared/store/currency-pairs/currency-pairs-facade.service';

const CURRENCY_PAIRS_FILTER_OPTIONS = [
  { value: 'BTC', active: false },
  { value: 'LTC', active: false },
  { value: 'CZK', active: false },
  { value: 'EUR', active: false },
];

@Component({
  selector: 'app-currency-pair-selector-modal',
  templateUrl: './currency-pair-selector-modal.component.html',
  styleUrls: ['./currency-pair-selector-modal.component.scss'],
})
export class CurrencyPairSelectorModalComponent implements OnInit, OnDestroy {
  @Input() modalId = '';
  @Input() modalActions: {
    pairChanged: (pair: CurrencyPairWithStatsDto) => void;
  } = {
    pairChanged: () => {},
  };

  @ViewChild(MultiChoiceButtonBarComponent) multiChoiceButtonBar!: MultiChoiceButtonBarComponent;

  headers = ['currency-pair', 'currencies.table.header.change', 'currencies.table.header.price'];

  currencyPairs: CurrencyPairWithStatsDto[] = [];
  favouritePairs: string[] = [];

  filteredRestPairs: CurrencyPairWithStatsDto[] = [];
  searchTerm = '';
  selectedPairs: string[] = [];

  filterOptions = CURRENCY_PAIRS_FILTER_OPTIONS;

  private unsubscribe$ = new Subject<void>();

  constructor(private translate: TranslateService, private currencyPairsService: CurrencyPairs) {}

  //region lifecycle
  ngOnInit(): void {
    combineLatest([
      this.currencyPairsService.currencyPairsWithStats$,
      this.currencyPairsService.favouriteCurrencyPairs$,
    ])
      .pipe(
        map(([currencyPairs, favouritePairs]) => {
          return {
            currencyPairs,
            favouritePairs: favouritePairs.map((pair) => pair.name),
          };
        }),
        takeUntil(this.unsubscribe$),
      )
      .subscribe(({ currencyPairs, favouritePairs }) => {
        this.currencyPairs = currencyPairs;
        this.favouritePairs = favouritePairs;
        this.updateRestPairs();
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
  //endregion

  //region inputs
  onSearchTermChange(searchTerm: string) {
    const lowerCaseSearchTerm = searchTerm.toLowerCase();
    this.searchTerm = searchTerm;
    this.selectedPairs = [];
    this.filterOptions = this.filterOptions.map((option) => ({
      ...option,
      active: false,
    }));
    this.filteredRestPairs = this.getFilteredCurrencyPairsBySearchTerm(lowerCaseSearchTerm);
  }

  handleSelectedOptions(selected: any[]) {
    this.searchTerm = '';
    this.selectedPairs = selected;
    this.filteredRestPairs = this.getFilteredCurrencyPairsByPairs(selected);
  }

  onRowClick(pair: CurrencyPairWithStatsDto) {
    this.modalActions.pairChanged(pair);
  }

  onIconClick(event: Event, pair: CurrencyPairWithStatsDto) {
    event.stopPropagation();

    const action = this.favouritePairs.includes(pair.name) ? 'remove' : 'add';
    if (action === 'add') {
      this.addToFavourite(pair);
    } else {
      this.removeFromFavourite(pair);
    }
  }
  //endregion

  //region outputs
  listFavouritePairs(): CurrencyPairWithStatsDto[] {
    return this.currencyPairs.filter((pair) => {
      return this.favouritePairs.includes(pair.name);
    });
  }
  //endregion

  //region private
  private addToFavourite = (currencyPair: CurrencyPairWithStatsDto): void => {
    this.currencyPairsService.addFavouritePair(currencyPair);
  };

  private removeFromFavourite = (currencyPair: CurrencyPairDto): void => {
    this.currencyPairsService.removeFavouritePair(currencyPair.name);
  };

  private listRestPairs(): CurrencyPairWithStatsDto[] {
    return this.currencyPairs.filter((pair) => {
      return !this.favouritePairs.includes(pair.name);
    });
  }

  private getFilteredCurrencyPairsByPairs(selected: string[]): CurrencyPairWithStatsDto[] {
    if (!selected.length) {
      return this.listRestPairs();
    }

    return this.listRestPairs().filter(
      (pair) => selected.includes(pair.firstCurrency) || selected.includes(pair.secondCurrency),
    );
  }

  resetFilterOptions() {
    this.multiChoiceButtonBar.resetOptions();
  }

  private getFilteredCurrencyPairsBySearchTerm(searchTerm: string) {
    return this.listRestPairs().filter((pair) => {
      return (
        pair.firstCurrency.toLowerCase().includes(searchTerm) ||
        pair.secondCurrency.toLowerCase().includes(searchTerm) ||
        this.matchesSearchTerm(this.getCurrencyDisplayName(pair.firstCurrency), searchTerm) ||
        this.matchesSearchTerm(this.getCurrencyDisplayName(pair.secondCurrency), searchTerm) ||
        this.getCurrencyDisplayName(pair.firstCurrency).toLowerCase().includes(searchTerm) ||
        this.getCurrencyDisplayName(pair.secondCurrency).toLowerCase().includes(searchTerm)
      );
    });
  }

  private matchesSearchTerm(value: string, searchTerm: string): boolean {
    return value
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .toLowerCase()
      .includes(searchTerm);
  }

  private getCurrencyDisplayName(currencyCode: string): string {
    const displayName = CurrencyDisplayName[currencyCode as keyof typeof CurrencyDisplayName];
    return displayName === 'crypto.CZK' ? this.translate.instant(displayName) : displayName;
  }

  private updateRestPairs() {
    this.filteredRestPairs = this.selectedPairs.length
      ? this.getFilteredCurrencyPairsByPairs(this.selectedPairs)
      : this.getFilteredCurrencyPairsBySearchTerm(this.searchTerm);
  }
  //endregion
}
