import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

import { DropdownService } from '@app/shared/components/dropdown/dropdown.service';
import { takeUntil, withLatestFrom } from 'rxjs/operators';

@Injectable()
export class DropdownPopoverService implements OnDestroy {
  popoverVisible$: Observable<boolean> = of(false);

  protected readonly unsubscribe$ = new Subject<void>();

  private popoverVisibleSubject$ = new BehaviorSubject<boolean>(false);
  private dropdownId = '';

  constructor(private dropdownService: DropdownService) {
    this.popoverVisible$ = this.popoverVisibleSubject$.asObservable();
    this.dropdownId = uuidv4();

    this.dropdownService.openDropdownId$
      .pipe(takeUntil(this.unsubscribe$), withLatestFrom(this.popoverVisibleSubject$))
      .subscribe(([dropdownId, isVisible]) => {
        // Close dropdown if another dropdown has opened
        if (dropdownId !== this.dropdownId) {
          this.popoverVisibleSubject$.next(false);
        }
      });
  }

  ngOnDestroy() {
    this.popoverVisibleSubject$.next(false);

    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  open() {
    this.popoverVisibleSubject$.next(true);
    this.dropdownService.openDropdown(this.dropdownId);
  }

  close() {
    this.popoverVisibleSubject$.next(false);
    this.dropdownService.closeDropdown();
  }

  toggle() {
    if (this.popoverVisibleSubject$.getValue()) {
      this.close();
    } else {
      this.open();
    }
  }
}
