import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { BalanceWithCurrencyEquivalents } from '@app/shared/store/balances/balances.selectors';
import { distinctUntilChanged, map, switchMap, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, debounceTime, Observable, of, Subject } from 'rxjs';
import { CurrencyDto } from '@app/generated/models/currency-dto';

const DEBOUNCE_TIME = 100;

@Component({
  selector: 'app-context-trade-currency-modal',
  templateUrl: './context-trade-currency-modal.component.html',
  styleUrls: ['./context-trade-currency-modal.component.scss'],
})
export class ContextTradeCurrencyModalComponent implements OnInit, OnDestroy {
  @Input() modalId = '';
  @Input() modalData: {
    balances: Observable<BalanceWithCurrencyEquivalents[]>;
    onlyAvailableBalances?: boolean;
  } = {
    balances: of([]),
    onlyAvailableBalances: true,
  };
  @Input() modalActions: {
    onCurrencySelect?: (currency: CurrencyDto) => void;
  } = {};

  headers = [
    'trading-platform.modal.balances.headers.currency',
    'trading-platform.modal.balances.headers.total-balance',
    'trading-platform.modal.balances.headers.in-open-orders',
    'trading-platform.modal.balances.headers.available',
  ];

  filteredCurrencyBalances: BalanceWithCurrencyEquivalents[] = [];
  previousSearchTerm = '';

  private unsubscribe$ = new Subject<void>();
  private filter$ = new BehaviorSubject<string>('');

  constructor(private translateService: TranslateService) {}

  ngOnInit(): void {
    this.modalData.balances.pipe(takeUntil(this.unsubscribe$)).subscribe((balances) => {
      this.filter$.next(this.previousSearchTerm);
    });

    this.filter$
      .pipe(
        debounceTime(DEBOUNCE_TIME),
        distinctUntilChanged(),
        switchMap((searchTerm) => this.filterCurrencyBalances(searchTerm)),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((filteredCurrencyBalances) => {
        this.filteredCurrencyBalances = filteredCurrencyBalances;
        this.previousSearchTerm = this.filter$.value;
      });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onSearchTermChange(searchTerm: string) {
    this.filter$.next(searchTerm);
  }

  getCurrencyDisplayName(currencyCode: string): string {
    const key = `crypto.${currencyCode}`;
    const translation = this.translateService.instant(key);
    return key === translation ? currencyCode : translation;
  }

  private filterCurrencyBalances(searchTerm: string): Observable<any> {
    return this.modalData.balances.pipe(
      map((data) => {
        const lowerCaseSearchTerm = searchTerm.toLowerCase();
        return data
          .filter((currency) =>
            !this.modalData.onlyAvailableBalances ? true : currency.available > 0 || currency.inOpenOrders > 0,
          )
          .filter((currency) => {
            return (
              currency.name.toLowerCase().includes(lowerCaseSearchTerm) ||
              this.getCurrencyDisplayName(currency.name)
                // next two lines are for diacritics insensitive search
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')
                .toLowerCase()
                .includes(lowerCaseSearchTerm) ||
              this.getCurrencyDisplayName(currency.name).toLowerCase().includes(lowerCaseSearchTerm)
            );
          })
          .sort((a, b) => {
            return (
              (b.name === 'CZK' ? 1 : 0) - (a.name === 'CZK' ? 1 : 0) ||
              (b.name === 'EUR' ? 1 : 0) - (a.name === 'EUR' ? 1 : 0) ||
              b.fiatEquivalentsTotal.CZK - a.fiatEquivalentsTotal.CZK
            );
          });
      }),
    );
  }
}
