<div class="flex items-center space-x-4">
  <span class="hidden md:inline text-cm-grey-400 text-[14px] font-sans font-normal dark:text-app-dark-mode-white">
    {{ description }}
  </span>

  <button class="modal-multi-select" (click)="resetOptions()" [ngClass]="{ active: areAllOptionsInactive }">
    <span class="flex items-center">{{ 'ALL' | translate }}</span>
  </button>

  <button
    *ngFor="let option of options"
    class="modal-multi-select"
    (click)="selectOption(option)"
    [ngClass]="{ active: option.active }"
  >
    <span class="flex items-center">
      <svg-icon *ngIf="option.active" src="/assets/icons/icon-multibutton.svg" class="w-4 h-4 mr-1"></svg-icon>
      {{ option.value }}
    </span>
  </button>
</div>
