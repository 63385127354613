<div class="cm-paragraph cm-form-paragraph" [ngClass]="{ error: showError }">
  <label *ngIf="label" for="{{ id }}" translate>{{ label }}</label>
  <div>
    <div
      class="flex justify-center items-center w-auto bg-gray-100 border-t border-b border-l border-gray-300 hidden-empty"
    >
      <ng-content select="[app-input-addons-left]"></ng-content>
    </div>

    <div class="app-select">
      <select
        id="{{ id }}"
        class="max-w-full mb-0 outline-0 cm-input text-app-black"
        [ngClass]="{ 'border-red-500': showError, 'border-gray-500': !value }"
        [(ngModel)]="value"
        (focusout)="onTouched()"
        [autofocus]="autofocus"
      >
        <option *ngIf="placeholder" [value]="null" disabled translate>{{ placeholder || '' }}</option>
        <ng-content select="[app-option]"></ng-content>
      </select>
    </div>

    <span *ngIf="showError" class="clear-both cm-form-error-text"> {{ errorMessage || '' | translate }}</span>

    <div
      class="flex justify-center items-center w-16 bg-gray-100 border-t border-r border-b border-gray-300 hidden-empty"
    >
      <ng-content select="[app-input-addons-right]"></ng-content>
    </div>
  </div>
</div>
