import { createSelector, MemoizedSelector } from '@ngrx/store';
import { SharedState } from '../shared.reducer';
import { getSharedState } from '@app-shared/store/shared.selectors';
import BigNumber from 'bignumber.js';
import { queryCurrencyPairsWithStats } from '@app/shared/store/currency-pairs/currency-pairs.selectors';
import { CurrencyBalanceWithEquivalentsDto } from '@app/generated/models/currency-balance-with-equivalents-dto';
import { queryCurrencies } from '@app/shared/store/currencies/currencies.selectors';
import { CurrencyDto } from '@app/generated/models/currency-dto';

export interface BalanceWithCurrencyEquivalents extends CurrencyDto {
  totalBalance: number;
  available: number;
  inOpenOrders: number;
  lastPrices: { EUR: number; CZK: number };
  changeIn24Hours: { EUR: number; CZK: number };
  fiatEquivalentsTotal: {
    [key: string]: number;
  };
  fiatEquivalentsAvailable: {
    [key: string]: number;
  };
}

export const queryCurrencyBalances = () => {
  return createSelector(getSharedState, (state: SharedState) => state.balances);
};

export const createBalancesWithCurrencyEquivalentsSelector = (
  currencyBalancesSelector: MemoizedSelector<object, Record<string, CurrencyBalanceWithEquivalentsDto>>,
) =>
  createSelector(
    currencyBalancesSelector,
    queryCurrencyPairsWithStats(),
    queryCurrencies(),
    (balances, currencyPairsWithStats, allAvailableCurrencies): BalanceWithCurrencyEquivalents[] => {
      if (!(balances && currencyPairsWithStats && allAvailableCurrencies)) return [];

      return allAvailableCurrencies.reduce((acc: BalanceWithCurrencyEquivalents[], currency) => {
        const balance = balances[currency.name];
        if (!balance) return acc;

        const balanceTotalBN = BigNumber(balance.totalBalance || 0);
        const balanceAvailableBN = BigNumber(balance.available || 0);
        const currencyPairInCZK = currencyPairsWithStats.find(
          (currencyPair) => currencyPair.firstCurrency === currency.name && currencyPair.secondCurrency === 'CZK',
        );
        const currencyPairInEUR = currencyPairsWithStats.find(
          (currencyPair) => currencyPair.firstCurrency === currency.name && currencyPair.secondCurrency === 'EUR',
        );
        const currencyPairInCZKLastPriceBN = BigNumber(currencyPairInCZK?.lastPrice ?? 0);
        const currencyPairInEURLastPriceBN = BigNumber(currencyPairInEUR?.lastPrice ?? 0);
        const currencyPairInCZKChangePrice = currencyPairInCZK?.changeIn24Hours ?? 0;
        const currencyPairInEURChangePrice = currencyPairInEUR?.changeIn24Hours ?? 0;

        acc.push({
          ...currency,
          totalBalance: balance.totalBalance,
          inOpenOrders: balance.inOpenOrders,
          available: balance.available,
          fiatEquivalentsTotal: currency.virtual
            ? {
                CZK: balanceTotalBN.times(currencyPairInCZKLastPriceBN).toNumber(),
                EUR: balanceTotalBN.times(currencyPairInEURLastPriceBN).toNumber(),
              }
            : {
                CZK: balance.fiatEquivalents.CZK,
                EUR: balance.fiatEquivalents.EUR,
              },
          fiatEquivalentsAvailable: currency.virtual
            ? {
                CZK: balanceAvailableBN.times(currencyPairInCZKLastPriceBN).toNumber(),
                EUR: balanceAvailableBN.times(currencyPairInEURLastPriceBN).toNumber(),
              }
            : {
                CZK: balance.fiatEquivalents.CZK,
                EUR: balance.fiatEquivalents.EUR,
              },
          lastPrices: {
            CZK: currencyPairInCZKLastPriceBN.toNumber(),
            EUR: currencyPairInEURLastPriceBN.toNumber(),
          },
          changeIn24Hours: {
            CZK: currencyPairInCZKChangePrice,
            EUR: currencyPairInEURChangePrice,
          },
        });

        return acc;
      }, []);
    },
  );

export const queryCurrencyBalancesWithEquivalents = () =>
  createBalancesWithCurrencyEquivalentsSelector(queryCurrencyBalances());
