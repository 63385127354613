import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  Renderer2,
  ViewChild,
} from '@angular/core';
// @ts-ignore
import { map, Subject } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';
import { GuiParams } from '@app/shared/store/gui-params/gui-params-facade.service';
import { UserInfo } from '@app/shared/store/user-info/user-info-facade.service';
import { LogoutService } from '@app/authentication/shared/services/logout.service';
import { LocalizedRouterService } from '@app/shared/services/localized-router.service';
import { LanguageSwitchTypeEnum } from '@app/shared/components/language-switch/language-switch.component';
import { isPlatformBrowser, Location } from '@angular/common';
import { SelectedPairControllerService } from '@app/generated/services/selected-pair-controller.service';
import { UserAccountControllerService } from '@app/generated/services/user-account-controller.service';
import { UserMessagesControllerService } from '@app/generated/services/user-messages-controller.service';
import { TranslateService } from '@ngx-translate/core';
import { CurrenciesControllerService } from '@app/generated/services/currencies-controller.service';
import { LanguageService } from '@app/shared/services/language.service';
import { CurrencyPairDto } from '@app/generated/models/currency-pair-dto';
import { CurrencyBalanceWithEquivalentsDto } from '@app/generated/models/currency-balance-with-equivalents-dto';
import { AccountDto } from '@app/generated/models/account-dto';
import { CurrencyDto } from '@app/generated/models/currency-dto';
import { UnreadMessageCountDto } from '@app/generated/models/unread-message-count-dto';
import { NavigationEnd, Router } from '@angular/router';
import { PortfolioService } from '@app/authenticated/portfolio/services/portfolio.service';

interface UserMenuLink {
  name: string;
  url: string;
}

type MenuItem = {
  hideWhenLogged: boolean;
  czOnly: boolean;
  enOnly: boolean;
  name: string;
  showSubMenu: boolean;
  url?: string;
  subMenu?: SubMenuItem[];
};

type SubMenuItem = {
  name: string;
  url: string;
};

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit, OnDestroy {
  @Input() isSecured = false;
  @Input() isBasic = false;

  @ViewChild('accountSelect') accountSelect!: HTMLSelectElement;
  @ViewChild('accountSelectDropdown') accountSelectDropdown!: ElementRef;

  @HostBinding('class.mobile-menu-open') get isMobileMenuOpen() {
    return !this.isClosed;
  }

  appState = 'operational';
  isQuickTrade = false;
  isTradingPlatform = false;
  isPortfolio = false;
  appState$ = this.guiParams.appState$;
  loggedIn$ = this.guiParams.loggedIn$;
  userInfo$ = this.userInfo.userInfo$;
  isClosed = true;
  accountChangeDisabled = false;
  showAccountMenu = false;
  showAccounts = false;
  selectedPair?: CurrencyPairDto;
  balances: { [key: string]: CurrencyBalanceWithEquivalentsDto | undefined } = {};
  accountList: AccountDto[] = [];
  selectedAccount?: AccountDto;
  currentLang = '';
  unreadMessagesCount: UnreadMessageCountDto = {
    count: 0,
  };

  availableCurrencies: CurrencyDto[] = [];
  languageSwitchTypeEnum = LanguageSwitchTypeEnum;

  menuLinks: MenuItem[] = [
    {
      hideWhenLogged: false,
      czOnly: false,
      enOnly: false,
      name: 'shared.footer.cryptocurrencies',
      showSubMenu: false,
      url: '/prices',
    },
    {
      hideWhenLogged: false,
      czOnly: true,
      enOnly: false,
      name: 'shared.menu.education',
      showSubMenu: false,
      subMenu: [
        {
          name: 'shared.menu.edu.articles',
          url: 'https://coinmate.io/cz/krypto-clanky/',
        },
        {
          name: 'shared.menu.edu.news',
          url: 'https://coinmate.io/cz/krypto-novinky/',
        },
        {
          name: 'shared.menu.edu.faq',
          url: '/faq',
        },
        {
          name: 'shared.menu.edu.glossary',
          url: 'https://coinmate.io/cz/krypto-slovnik/',
        },
        {
          name: 'shared.menu.edu.academy',
          url: 'https://coinmate.io/cz/krypto-akademie/',
        },
      ],
    },
  ];

  userMenuLinks: UserMenuLink[] = [];
  securedMenuLogoLink = '/';
  urlsWithAccountChangeEnabled = [
    // todo: change to blacklist instead of whitelist
    '/transaction-history',
    '/open-orders',
    '/order-history',
    '/quick-trade',
    '/recurring-buy',
    '/account-api',
    '/account-log',
    '/user-log',
    '/transfers',
    '/withdraw',
    '/deposit',
    '/trade',
  ];
  langPrefix = '';
  private unsubscribe$ = new Subject<void>();
  private globalClickListener: (() => void | undefined) | undefined;
  private isBrowser: boolean;

  constructor(
    private _location: Location,
    private guiParams: GuiParams,
    private userInfo: UserInfo,
    private logoutService: LogoutService,
    private selectedPairService: SelectedPairControllerService,
    private accountService: UserAccountControllerService,
    private messagesService: UserMessagesControllerService,
    private translate: TranslateService,
    private currenciesService: CurrenciesControllerService,
    private cdr: ChangeDetectorRef,
    private userInfoService: UserInfo,
    private languageService: LanguageService,
    private renderer: Renderer2,
    public router: LocalizedRouterService,
    private routerService: Router,
    private portfolioService: PortfolioService,
    @Inject(PLATFORM_ID) platformId: string,
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    const html = document.getElementsByTagName('html')[0];
    html.classList.remove('mobile-menu-shown');
    this.isClosed = true;
  }

  ngOnInit(): void {
    this.selectedPairService
      .getSelectedCurrencyPairUsingGet()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((selectedPair: CurrencyPairDto) => {
        this.selectedPair = selectedPair;
      });

    this.currenciesService
      .getCurrenciesUsingGet()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((currencies: CurrencyDto[]) => {
        this.availableCurrencies = currencies;
      });

    this.currentLang = this.translate.currentLang === 'en' ? 'cze' : 'eng';

    this.routerService.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.isClosed = true;
        const html = document.getElementsByTagName('html')[0];
        html.classList.remove('mobile-menu-shown');
        this.updateContextBar();
      }
    });

    this.updateLangPrefix();
    this.routerService.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
      this.updateLangPrefix();
    });

    this.languageService.currentLanguage$.pipe(takeUntil(this.unsubscribe$)).subscribe((lang: string) => {
      this.currentLang = lang;
      this.cdr.detectChanges();
    });

    this.appState$.pipe(takeUntil(this.unsubscribe$)).subscribe((appState) => {
      this.appState = appState;
    });

    this.loggedIn$.pipe(takeUntil(this.unsubscribe$)).subscribe((loggedIn: boolean) => {
      if (!loggedIn) {
        return;
      }
      this.accountService
        .getAllAccountsOfUserUsingGet({ balances: true })
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((accounts: AccountDto[]) => {
          this.accountList = accounts;
          this.accountService
            .getActiveGuiAccountUsingGet()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((account: AccountDto) => {
              this.selectedAccount = account;
              const accountData = this.accountList.find((acc) => acc.id === account.id);
              // @ts-ignore
              this.balances = accountData.balances;
            });
        });

      this.userInfoService.load();
      this.userInfo$ = this.userInfo.userInfo$;

      this.messagesService
        .getUnreadMessagesCountUsingGet()
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((unreadMessages: UnreadMessageCountDto) => {
          this.unreadMessagesCount = unreadMessages;
        });

      this.routerService.events
        .pipe(
          filter((event) => event instanceof NavigationEnd),
          map(() => this.router.url),
          takeUntil(this.unsubscribe$),
        )
        .subscribe((url) => {
          this.isQuickTrade = url.includes('/quick-trade');
        });
    });

    /**
     * This is not a nice solution. Account updates should be independent and have only one source of truth.
     * Suggestion: The accounts should be stored in a shared store.
     */
    this.portfolioService.onAccountUpdate$
      .pipe(
        takeUntil(this.unsubscribe$),
        filter(() => {
          return !!(this.accountList.length && this.selectedAccount);
        }),
      )
      .subscribe((accounts) => {
        this.accountService
          .getAllAccountsOfUserUsingGet({ balances: true })
          .pipe(take(1))
          .subscribe((accounts: AccountDto[]) => {
            this.accountList = accounts;
            this.accountService
              .getActiveGuiAccountUsingGet()
              .pipe(take(1))
              .subscribe((selectedAccount: AccountDto) => {
                const selectedAccountData = this.accountList.find((account) => account.id === selectedAccount.id);

                if (selectedAccountData) {
                  this.selectedAccount = selectedAccount;
                  this.balances = selectedAccount.balances as unknown as {
                    [key: string]: CurrencyBalanceWithEquivalentsDto | undefined;
                  };
                } else {
                  const mainAccount = this.accountList.find(({ position }) => position === 0) as AccountDto;
                  this.changeSelectedAccount(mainAccount.id);
                }
              });
          });
      });

    this.isQuickTrade = this.router.url.includes('/quick-trade');
    this.updateContextBar();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  updateContextBar() {
    // check if URL ends with /trade
    this.isTradingPlatform = this.isBrowser ? String(window.location).includes('/trade') : false;
    // check if URL ends with /portfolio
    this.isPortfolio = this.isBrowser ? String(window.location).includes('/portfolio') : false;
    let hasEnabledAccountChange = false;

    if (this.isBrowser) {
      // eslint-disable-next-line @typescript-eslint/prefer-for-of
      for (let i = 0; i < this.urlsWithAccountChangeEnabled.length; i++) {
        if (window.location.href.indexOf(this.urlsWithAccountChangeEnabled[i]) > -1) {
          hasEnabledAccountChange = true;
          break;
        }
      }
    }

    this.accountChangeDisabled = !hasEnabledAccountChange;

    if (this.appState === 'operational') {
      this.userMenuLinks = [
        {
          name: 'shared.menu.profile',
          url: '/account-profile',
        },
        {
          name: 'shared.menu.security',
          url: '/security',
        },
        {
          name: 'Verification',
          url: '/verification',
        },
        {
          name: 'shared.menu.price-alerts',
          url: '/price-alerts',
        },
        {
          name: 'API',
          url: '/account-api',
        },
        {
          name: 'shared.menu.user-log',
          url: '/user-log',
        },
        {
          name: 'shared.menu.referral',
          url: '/referral',
        },
      ];
    }

    if (this.appState === 'verification') {
      this.securedMenuLogoLink = '/verification';
    } else {
      this.securedMenuLogoLink = '/quick-trade';
    }
  }

  logout = () => {
    this.logoutService.logout();
  };

  changeSelectedAccount(accountId?: number | string) {
    if (accountId === undefined) {
      return;
    }
    this.accountService
      .setActiveGuiAccountUsingPut({
        body: Number(accountId),
      })
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((account: AccountDto) => {
        this.selectedAccount = account;
        this.userInfoService.emitEvent({
          account,
        });
        this.showAccounts = false;
        this.isClosed = true;
      });
  }

  navigateBack = () => {
    if (this.router.url.endsWith('/login') || this.router.url.endsWith('/sign-up')) {
      this.router.navigateByUrl('/').catch(() => {});
    } else {
      this.router.navigateByUrl('/login').catch(() => {});
    }
  };

  navigate = (url?: string) => {
    this.isClosed = true;
    const html = document.getElementsByTagName('html')[0];
    html.classList.remove('mobile-menu-shown');
    if (url) {
      this.router.navigateByUrl(url).catch(() => {});
    }
  };

  toggleMenu() {
    if (window.innerWidth > 1100) {
      return;
    }

    this.isClosed = !this.isClosed;
    const html = document.getElementsByTagName('html')[0];
    if (!this.isClosed) html.classList.add('mobile-menu-shown');
    else html.classList.remove('mobile-menu-shown');
  }

  toggleSubMenu(link: any, event: Event) {
    event.stopPropagation();
    link.showSubMenu = !link.showSubMenu;
    // listen for click events on the document
    this.globalClickListener = this.renderer.listen('document', 'click', () => {
      link.showSubMenu = false;
      if (this.globalClickListener) this.globalClickListener(); // stop listening to click events
    });
  }

  toggleAccountMenu(event: Event) {
    event.stopPropagation();
    this.showAccountMenu = !this.showAccountMenu;
    // listen for click events on the document
    this.globalClickListener = this.renderer.listen('document', 'click', () => {
      this.showAccountMenu = false;
      if (this.globalClickListener) this.globalClickListener(); // stop listening to click events
    });
  }

  toggleAccounts(event: Event) {
    event.stopPropagation();
    if (this.accountChangeDisabled) {
      return;
    }

    this.showAccounts = !this.showAccounts;
  }

  private updateLangPrefix(): void {
    const urlSegments = this.routerService.url.split('/');
    this.langPrefix = urlSegments[1];
  }

  private updateAccountList() {}
}
