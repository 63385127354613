import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { loadBalances, setBalances, stopUpdating, updateBalance } from '@app/shared/store/balances/balances.actions';
import { UserAccountControllerService } from '@app/generated/services/user-account-controller.service';
import { PushpinService } from '@app/shared/services/pushpin.service';
import { NavigationStart, Router } from '@angular/router';

@Injectable()
export class BalancesEffects {
  loadBalances$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadBalances),
      switchMap((action) => {
        return this.accountService.getActiveGuiAccountUsingGet({ balances: true }).pipe(
          map(({ balances }) => {
            return setBalances({ balances });
          }),
          catchError((e) => {
            throw e;
          }),
        );
      }),
    ),
  );

  watchBalances$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setBalances),
      switchMap((action) =>
        this.pushpinService.getBalances$().pipe(
          map(({ balances }) => {
            return updateBalance({ balances });
          }),
          catchError((e) => {
            throw e;
          }),
          takeUntil(this.actions$.pipe(ofType(loadBalances))),
          takeUntil(this.actions$.pipe(ofType(stopUpdating))),
          takeUntil(this.navigationStart$),
        ),
      ),
    ),
  );

  private navigationStart$ = this.router.events.pipe(filter((event) => event instanceof NavigationStart));

  constructor(
    private actions$: Actions,
    private accountService: UserAccountControllerService,
    private pushpinService: PushpinService,
    private router: Router,
  ) {}
}
