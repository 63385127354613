<ng-container *ngIf="!loading; else loader">
  <ng-container *ngIf="data !== null">
    <div class="balances-table">
      <!-- SEARCH, FILTER -->
      <div class="flex items-center justify-between relative mb-[20px] z-[100]">
        <div class="w-full md:w-auto flex items-center">
          <form>
            <label for="search" class="sr-only">
              {{ 'balances-table.search-placeholder' | translate }}
            </label>
            <input
              type="text"
              name="search"
              id="search"
              autocomplete="off"
              (input)="onSearchTermChange($event)"
              [placeholder]="'balances-table.search-placeholder' | translate"
            />
            <div class="search">
              <svg-icon src="/assets/icons/24/search.svg"></svg-icon>
            </div>
          </form>
          <div class="applied-filter" *ngIf="showWithAmountOnly$ | async">
            <span>{{ 'balances-table.with-balance' | translate }}</span>
            <button (click)="onToggleWithAmountOnly()">
              <svg-icon src="/assets/icons/icon-remove-nobg.svg"></svg-icon>
            </button>
          </div>
          <div class="applied-filter" *ngIf="showFavoritesOnly$ | async">
            <span>
              {{ 'balances-table.favourites' | translate }}
            </span>
            <button (click)="onToggleFavoritesOnly()">
              <svg-icon src="/assets/icons/icon-remove-nobg.svg"></svg-icon>
            </button>
          </div>
        </div>
        <div class="relative">
          <ng-container *ngTemplateOutlet="filterDropdown"></ng-container>
        </div>
      </div>

      <!-- TABLE -->
      <div class="table-container">
        <ng-container *ngIf="filteredCurrencies.length; else noBalances">
          <table>
            <tr class="no-background no-border">
              <th colspan="3">
                {{ 'balances-table.currency' | translate }}
              </th>
              <th *ngIf="cellTotalBalanceRef" class="table-balance">
                {{ 'balances-table.total-balance' | translate }}
              </th>
              <th *ngIf="cellPriceRef" class="table-price">
                {{ 'balances-table.price' | translate }}
              </th>
              <th *ngIf="cellChangeRef" class="table-change">
                {{ 'balances-table.change' | translate }}
              </th>
              <th *ngIf="actionButtonsRef || actionDropdownRef" class="!hidden md:!table-cell"></th>
            </tr>

            <ng-container *ngIf="selectedFiatCurrency$ | async as selectedFiatCurrency">
              <ng-container *ngFor="let currency of filteredCurrencies; let i = index">
                <ng-container *ngIf="i === 0 && currency.favorite">
                  <tr class="no-background no-border">
                    <td colspan="5" class="table-section-title">
                      {{ 'balances-table.favourites' | translate }}
                    </td>
                  </tr>
                </ng-container>
                <ng-container *ngIf="isStartOfNonFavorites(i, filteredCurrencies)">
                  <tr class="no-background no-border">
                    <td colspan="5" class="table-section-title">
                      {{ 'balances-table.other-currencies' | translate }}
                    </td>
                  </tr>
                </ng-container>
                <tr>
                  <td class="table-favorite">
                    <button class="like" [class.active]="currency.favorite" (click)="onToggleFavorite(currency)">
                      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M9.39076 3.03273C9.58369 2.59991 9.68015 2.38351 9.81448 2.31686C9.93116 2.25896 10.0682 2.25896 10.1849 2.31686C10.3192 2.38351 10.4157 2.59991 10.6086 3.03273L12.1453 6.48017C12.2023 6.60812 12.2308 6.6721 12.275 6.72109C12.314 6.76444 12.3617 6.79911 12.415 6.82285C12.4753 6.84968 12.5449 6.85703 12.6842 6.87173L16.4378 7.2679C16.9091 7.31764 17.1447 7.34251 17.2496 7.44966C17.3407 7.54274 17.383 7.67307 17.364 7.80193C17.3422 7.95027 17.1661 8.10889 16.8141 8.42612L14.0103 10.9529C13.9062 11.0467 13.8542 11.0936 13.8212 11.1507C13.7921 11.2012 13.7738 11.2573 13.7677 11.3154C13.7608 11.3809 13.7754 11.4494 13.8044 11.5865L14.5876 15.2788C14.6859 15.7423 14.735 15.9741 14.6656 16.107C14.6052 16.2224 14.4943 16.3029 14.3659 16.3247C14.2181 16.3497 14.0128 16.2313 13.6023 15.9946L10.3328 14.1088C10.2114 14.0388 10.1507 14.0038 10.0862 13.9901C10.0292 13.978 9.97019 13.978 9.91312 13.9901C9.84862 14.0038 9.78794 14.0388 9.66659 14.1088L6.39702 15.9946C5.98654 16.2313 5.7813 16.3497 5.63345 16.3247C5.50503 16.3029 5.39416 16.2224 5.3338 16.107C5.2643 15.9741 5.31346 15.7423 5.41178 15.2788L6.19492 11.5865C6.22399 11.4494 6.23852 11.3809 6.23162 11.3154C6.22551 11.2573 6.20729 11.2012 6.17812 11.1507C6.14516 11.0936 6.09313 11.0467 5.98907 10.9529L3.18522 8.42612C2.83321 8.10889 2.6572 7.95027 2.63532 7.80193C2.61632 7.67307 2.65867 7.54274 2.74978 7.44966C2.85468 7.34251 3.0903 7.31764 3.56155 7.2679L7.31513 6.87173C7.45444 6.85703 7.5241 6.84968 7.58433 6.82285C7.63762 6.79911 7.68534 6.76444 7.72439 6.72109C7.76851 6.6721 7.79703 6.60812 7.85407 6.48017L9.39076 3.03273Z"
                          stroke="#3E68FF"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </button>
                  </td>
                  <td class="table-icon">
                    <img
                      class="h-8 w-8 rounded-full"
                      [src]="'/assets/crypto/color/' + currency.name.toLowerCase() + '.svg'"
                      alt="{{ currency.name }}"
                    />
                  </td>
                  <td class="table-currency">
                    <p>
                      <strong>{{ currency.displayName | translate }}</strong>
                      {{ currency.name }}
                    </p>
                  </td>
                  <td *ngIf="cellTotalBalanceRef" class="table-balance">
                    <ng-container
                      *ngTemplateOutlet="cellTotalBalanceRef; context: { $implicit: currency, selectedFiatCurrency }"
                    ></ng-container>
                  </td>
                  <td *ngIf="cellPriceRef" class="table-price">
                    <ng-container
                      *ngTemplateOutlet="cellPriceRef; context: { $implicit: currency, selectedFiatCurrency }"
                    ></ng-container>
                  </td>
                  <td *ngIf="cellChangeRef" class="table-change">
                    <ng-container
                      *ngTemplateOutlet="cellChangeRef; context: { $implicit: currency, selectedFiatCurrency }"
                    ></ng-container>
                  </td>
                  <td *ngIf="actionButtonsRef || actionDropdownRef" class="table-buttons">
                    <ng-container
                      *ngTemplateOutlet="
                        actionButtonsRef;
                        context: {
                          $implicit: currency,
                          depositBlocked,
                          depositVerified,
                          withdrawalBlocked,
                          withdrawalBlockedUntilDate
                        }
                      "
                    >
                    </ng-container>

                    <ng-container
                      *ngTemplateOutlet="
                        actionDropdownRef;
                        context: {
                          $implicit: currency,
                          depositBlocked,
                          depositVerified,
                          withdrawalBlocked,
                          withdrawalBlockedUntilDate
                        }
                      "
                    >
                    </ng-container>
                  </td>
                </tr>
              </ng-container>
            </ng-container>
          </table>
        </ng-container>
      </div>
    </div>

    <ng-template #filterDropdown>
      <app-dropdown class="filter-dropdown">
        <button dropdown-trigger>
          <svg-icon class="text-cm-blue" [src]="'/assets/icons/icon-filter-list.svg'"></svg-icon>
          <span *ngIf="filtersCount$ | async as showFilterButton" class="filters-count">{{ showFilterButton }}</span>
        </button>
        <button (click)="onToggleFavoritesOnly()" dropdown-item>
          {{ 'balances-table.favourites' | translate }}
          <span *ngIf="showFavoritesOnly$ | async" dropdown-icon-right
            ><svg-icon [src]="'/assets/icons/icon-check.svg'"></svg-icon
          ></span>
        </button>
        <button (click)="onToggleWithAmountOnly()" dropdown-item>
          {{ 'balances-table.with-balance' | translate }}
          <span *ngIf="showWithAmountOnly$ | async" dropdown-icon-right
            ><svg-icon [src]="'/assets/icons/icon-check.svg'"></svg-icon
          ></span>
        </button>
      </app-dropdown>
    </ng-template>
  </ng-container>
</ng-container>

<ng-template #loader>
  <app-loader></app-loader>
</ng-template>

<ng-template #noBalances
  ><div class="mb-[20px] text-center text-cm-grey-300 dark:text-cm-grey-200 text-sm">
    {{ 'error.balances.no-available-balances' | translate }}
  </div></ng-template
>
