<ng-container *ngTemplateOutlet="!link ? buttonTpl : linkTpl"> </ng-container>

<ng-template #linkTpl>
  <app-tooltip [text]="tooltip">
    <a [routerLink]="link" [routerLinkActive]="'active'" class="menu-item-icon">
      <span class="shape">
        <svg-icon src="/assets/icons/bg-shape.svg" class="bg"></svg-icon>
        <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
      </span>
      <span class="icon-label">
        {{ tooltip }}
      </span>
    </a>
  </app-tooltip>
</ng-template>

<ng-template #buttonTpl>
  <app-tooltip [text]="tooltip">
    <button type="button" class="menu-item-icon">
      <span class="shape">
        <svg-icon src="/assets/icons/bg-shape.svg" class="bg"></svg-icon>
        <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
      </span>
      <span class="lg:hidden content">
        <ng-content select="[content]"></ng-content>
      </span>
    </button>
  </app-tooltip>
</ng-template>

<ng-template #contentTpl>
  <ng-content></ng-content>
</ng-template>
