import { NgModule } from '@angular/core';

import { DropdownService } from '@app/shared/components/dropdown/dropdown.service';
import { DropdownComponent } from '@app/shared/components/dropdown/dropdown.component';
import { DropdownItemComponent } from '@app/shared/components/dropdown/dropdown-item/dropdown-item.component';
import { DropdownIconComponent } from '@app/shared/components/dropdown/dropdown-icon/dropdown-icon.component';
import {
  DropdownTriggerActionButtonDirective,
  DropdownTriggerDirective,
} from '@app/shared/components/dropdown/dropdown-trigger/dropdown-trigger.directive';

@NgModule({
  imports: [
    DropdownComponent,
    DropdownItemComponent,
    DropdownTriggerActionButtonDirective,
    DropdownIconComponent,
    DropdownTriggerDirective,
  ],
  exports: [
    DropdownComponent,
    DropdownItemComponent,
    DropdownTriggerActionButtonDirective,
    DropdownIconComponent,
    DropdownTriggerDirective,
  ],
  providers: [DropdownService],
})
export class DropdownModule {}
