/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { UsersControllerService } from './services/users-controller.service';
import { UsersForgottenPasswordControllerService } from './services/users-forgotten-password-controller.service';
import { SelectedPairControllerService } from './services/selected-pair-controller.service';
import { UserWithdrawalControllerService } from './services/user-withdrawal-controller.service';
import { UserVerificationControllerService } from './services/user-verification-controller.service';
import { UserSecurityControllerService } from './services/user-security-controller.service';
import { UserPersonsControllerService } from './services/user-persons-controller.service';
import { UserMessagesControllerService } from './services/user-messages-controller.service';
import { UserAlertsControllerService } from './services/user-alerts-controller.service';
import { UserAccountControllerService } from './services/user-account-controller.service';
import { UserGuiWebsocketChannelsControllerService } from './services/user-gui-websocket-channels-controller.service';
import { OpenGuiWebsocketChannelsControllerService } from './services/open-gui-websocket-channels-controller.service';
import { GoogleAuthControllerService } from './services/google-auth-controller.service';
import { UserWithdrawalBtcControllerService } from './services/user-withdrawal-btc-controller.service';
import { QuickTradeControllerService } from './services/quick-trade-controller.service';
import { UserProfileControllerService } from './services/user-profile-controller.service';
import { UserFingerprintControllerService } from './services/user-fingerprint-controller.service';
import { UserDepositControllerService } from './services/user-deposit-controller.service';
import { UserDepositFiatControllerService } from './services/user-deposit-fiat-controller.service';
import { UserCurrencyPairFavouritesControllerService } from './services/user-currency-pair-favourites-controller.service';
import { UserCurrenciesFavouritesControllerService } from './services/user-currencies-favourites-controller.service';
import { AccountApiKeysControllerService } from './services/account-api-keys-controller.service';
import { UserAmlControllerService } from './services/user-aml-controller.service';
import { WebsocketTypingControllerService } from './services/websocket-typing-controller.service';
import { SupportMessagesControllerService } from './services/support-messages-controller.service';
import { UserTransactionsControllerService } from './services/user-transactions-controller.service';
import { UserSecurityBankidControllerService } from './services/user-security-bankid-controller.service';
import { UserReferralControllerService } from './services/user-referral-controller.service';
import { UserControllerService } from './services/user-controller.service';
import { UserOrdersControllerService } from './services/user-orders-controller.service';
import { UserLogControllerService } from './services/user-log-controller.service';
import { UserInfoControllerService } from './services/user-info-controller.service';
import { IdentityCheckControllerService } from './services/identity-check-controller.service';
import { UserClosingReasonControllerService } from './services/user-closing-reason-controller.service';
import { UserAffiliateControllerService } from './services/user-affiliate-controller.service';
import { TradingViewControllerService } from './services/trading-view-controller.service';
import { TrezorControllerService } from './services/trezor-controller.service';
import { TradesControllerService } from './services/trades-controller.service';
import { SseControllerService } from './services/sse-controller.service';
import { OrdersControllerService } from './services/orders-controller.service';
import { MiscControllerService } from './services/misc-controller.service';
import { GuiParametersControllerService } from './services/gui-parameters-controller.service';
import { GraphControllerService } from './services/graph-controller.service';
import { FeesControllerService } from './services/fees-controller.service';
import { CurrencyPairsControllerService } from './services/currency-pairs-controller.service';
import { CurrenciesControllerService } from './services/currencies-controller.service';
import { CountriesControllerService } from './services/countries-controller.service';
import { BankidControllerService } from './services/bankid-controller.service';
import { UsersAlertsControllerService } from './services/users-alerts-controller.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    UsersControllerService,
    UsersForgottenPasswordControllerService,
    SelectedPairControllerService,
    UserWithdrawalControllerService,
    UserVerificationControllerService,
    UserSecurityControllerService,
    UserPersonsControllerService,
    UserMessagesControllerService,
    UserAlertsControllerService,
    UserAccountControllerService,
    UserGuiWebsocketChannelsControllerService,
    OpenGuiWebsocketChannelsControllerService,
    GoogleAuthControllerService,
    UserWithdrawalBtcControllerService,
    QuickTradeControllerService,
    UserProfileControllerService,
    UserFingerprintControllerService,
    UserDepositControllerService,
    UserDepositFiatControllerService,
    UserCurrencyPairFavouritesControllerService,
    UserCurrenciesFavouritesControllerService,
    AccountApiKeysControllerService,
    UserAmlControllerService,
    WebsocketTypingControllerService,
    SupportMessagesControllerService,
    UserTransactionsControllerService,
    UserSecurityBankidControllerService,
    UserReferralControllerService,
    UserControllerService,
    UserOrdersControllerService,
    UserLogControllerService,
    UserInfoControllerService,
    IdentityCheckControllerService,
    UserClosingReasonControllerService,
    UserAffiliateControllerService,
    TradingViewControllerService,
    TrezorControllerService,
    TradesControllerService,
    SseControllerService,
    OrdersControllerService,
    MiscControllerService,
    GuiParametersControllerService,
    GraphControllerService,
    FeesControllerService,
    CurrencyPairsControllerService,
    CurrenciesControllerService,
    CountriesControllerService,
    BankidControllerService,
    UsersAlertsControllerService,
    ApiConfiguration
  ],
})
export class ApiModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: ApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
