import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss'],
})
export class MessageComponent {
  @Input() showMessage = false;
  @Input() type: 'info' | 'warning' | 'error' | 'success' = 'info';
  @Input() title?: string;
  @Input() message = '';
  @Input() showTitleSection = false;
  @Input() showCloseButton = false;
}

export type MessageComponentTypeType = MessageComponent['type'];
