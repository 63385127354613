import {
  AfterViewChecked,
  Component,
  ElementRef,
  Input,
  OnInit,
  Renderer2,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';

import { DropdownPopoverService } from '@app/shared/components/dropdown/dropdown-popover.service';
import { PopoverPosition } from '@app/shared/components/dropdown/dropdown.enum';

export const DESKTOP_BREAKPOINT = 768;

@Component({
  selector: 'app-dropdown',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  host: {
    '(document:click)': 'clickOutside($event)',
    '(click)': 'clickInside($event)',
    '[class]': 'visible ? "dropdown-open" : ""',
    '[attr.aria-haspopup]': '"menu"',
    '[attr.aria-expanded]': 'visible',
  },
  providers: [DropdownPopoverService],
  encapsulation: ViewEncapsulation.None,
})
export class DropdownComponent implements OnInit, AfterViewChecked {
  @Input() popoverPosition = PopoverPosition.bottomEnd;
  @Input() dropdownClass?: string;
  @Input() popoverClass?: string;

  @ViewChild('dropdown') dropdownRef!: ElementRef;
  @ViewChild('dropdownPopoverContainer') dropdownPopoverContainerRef!: ElementRef;

  visible = false;

  protected readonly popoverPositionEnum = PopoverPosition;

  private isDropdownPopoverMovedToBody = false;

  constructor(
    private popoverService: DropdownPopoverService,
    private elementRef: ElementRef,
    private renderer: Renderer2,
  ) {}

  clickOutside(event: Event) {
    if (this.visible) {
      if (!this.elementRef.nativeElement.contains(event.target)) {
        this.popoverService.close();
      }
    }
  }

  clickInside(event: Event) {
    event.stopPropagation();

    if (this.visible) {
      const button = this.findParentButton(event.target as HTMLElement);
      if (button && !button.hasAttribute('disabled')) {
        this.popoverService.close();
      }
    }
  }

  ngOnInit() {
    this.popoverService.popoverVisible$.subscribe((visible) => {
      this.visible = visible;

      if (!this.visible) {
        this.movePopoverToDropdown();
      }
    });
  }

  private findParentButton(element: HTMLElement) {
    if (!element) {
      return null;
    }

    while (element) {
      if (element.classList.contains('dropdown-trigger') || element.classList.contains('dropdown-popover')) {
        return null;
      }
      if (element.tagName.toLowerCase() === 'button') {
        return element;
      }
      element = element.parentElement as HTMLElement;
    }

    return null;
  }

  ngAfterViewChecked() {
    if (this.visible) {
      if (window.innerWidth < DESKTOP_BREAKPOINT) {
        this.movePopoverToBody();
      } else {
        this.movePopoverToDropdown();
      }
    } else {
      this.movePopoverToDropdown();
    }
  }

  private movePopoverToBody() {
    if (!this.isDropdownPopoverMovedToBody && this.dropdownPopoverContainerRef.nativeElement) {
      const body = this.renderer.selectRootElement('body', true);
      this.renderer.appendChild(body, this.dropdownPopoverContainerRef.nativeElement);
      this.isDropdownPopoverMovedToBody = true;
    }
  }

  private movePopoverToDropdown() {
    if (
      this.isDropdownPopoverMovedToBody &&
      this.dropdownRef.nativeElement &&
      this.dropdownPopoverContainerRef.nativeElement
    ) {
      this.renderer.appendChild(this.dropdownRef.nativeElement, this.dropdownPopoverContainerRef.nativeElement);
      this.isDropdownPopoverMovedToBody = false;
    }
  }
}
