import { Component, Input, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: '[dropdown-item]',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './dropdown-item.component.html',
  host: {
    '[class.dropdown-item]': 'true',
    '[class]': 'facet ? "dropdown-item-" + facet : ""',
  },
  encapsulation: ViewEncapsulation.None,
})
export class DropdownItemComponent {
  @Input() facet?: 'danger' | null;
}
