import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import BigNumber from 'bignumber.js';
import { DateTime, Interval } from 'luxon';

import { ApiStateStatus, PortfolioState } from '@app/authenticated/portfolio/store/portfolio.state';
import * as fromPortfolio from '@app/authenticated/portfolio/store/portfolio.reducer';
import {
  BalanceWithCurrencyEquivalents,
  createBalancesWithCurrencyEquivalentsSelector,
} from '@app/shared/store/balances/balances.selectors';
import { queryCurrencies } from '@app/shared/store/currencies/currencies.selectors';
import { getIntervals } from '@app/shared/utils/date';

import { AccountUpdateReason, TimePeriod } from '@app/authenticated/portfolio/ui/portfolio.enum';
import {
  AccountTotalBalance,
  AccountValueHistory,
  AccountValueHistoryByTimePeriod,
} from '@app/authenticated/portfolio/ui/portfolio.type';
import {
  getDateDiff,
  getIntervalUnitByTimePeriod,
  getTimePeriodDayOffset,
} from '@app/authenticated/portfolio/ui/portfolio.utils';

import { SelectableCurrency } from '@app/shared/services/selected-currency.service';

import { CurrencyBalanceWithEquivalentsDto } from '@app/generated/models/currency-balance-with-equivalents-dto';
import { CurrencyWithBalance } from '@app/shared/components/balances-table/balances-table.component';
import { AccountValueHistoryDto } from '@app/generated/models/account-value-history-dto';
import { BalancesWrapperDto } from '@app/generated/models/balances-wrapper-dto';
import { CurrencyDto } from '@app/generated/models/currency-dto';

const balancesFilter =
  (allAvailableCurrencies: CurrencyDto[], onlyAvailable: boolean = false) =>
  ([currencyName, balance]: [string, { totalBalance: number }]) => {
    if (onlyAvailable && balance.totalBalance === 0) {
      return false;
    }

    const currency = allAvailableCurrencies.find(({ name }) => name === currencyName);
    return currency && currency.active;
  };

const selectPortfolioState = createFeatureSelector<PortfolioState>(fromPortfolio.portfolioFeatureKey);

//region Account ID
export const selectSelectedAccountId = createSelector(selectPortfolioState, (state) => state.accountId);
//endregion

//region Account ID parameterized selector factory
const createAccountIdFromParamSelector = (accountId: number | null) =>
  createSelector(() => accountId) as MemoizedSelector<object, number | null>;
//endregion

//region Accounts
export const selectAccountsData = createSelector(selectPortfolioState, (state) => state.accounts.data);

export const selectAccountsStatus = createSelector(selectPortfolioState, (state) => state.accounts.status);
export const selectAccountsError = createSelector(selectPortfolioState, (state) => state.accounts.error);

export const selectActiveAccount = createSelector(selectSelectedAccountId, selectAccountsData, (accountId, accounts) =>
  accounts?.find((account) => account.id === accountId),
);
//endregion

//region Update Account
const selectUpdateAccountData = createSelector(selectPortfolioState, (state) => state.updateAccount);
export const selectUpdateAccountStatus = (reason: AccountUpdateReason) =>
  createSelector(
    selectPortfolioState,
    (state) => state.updateAccount.find((updateState) => updateState.meta.reason === reason)?.status || null,
  );
export const selectUpdateAccountError = (reason: AccountUpdateReason) =>
  createSelector(
    selectPortfolioState,
    (state) => state.updateAccount.find((updateState) => updateState.meta.reason === reason)?.error || null,
  );
export const selectUpdateAccountStatusById = (requestId: string, reason: AccountUpdateReason) =>
  createSelector(
    selectPortfolioState,
    (state) =>
      state.updateAccount.find(
        (updateState) => updateState.meta.requestId === requestId && updateState.meta.reason === reason,
      )?.status || null,
  );
export const selectUpdateAccountErrorById = (requestId: string, reason: AccountUpdateReason) =>
  createSelector(
    selectPortfolioState,
    (state) =>
      state.updateAccount.find(
        (updateState) => updateState.meta.requestId === requestId && updateState.meta.reason === reason,
      )?.error || null,
  );
//endregion

//region Account Balances total value
const computeAccountTotalBalancesValues = (accountBalances: BalancesWrapperDto) => {
  return Object.entries(accountBalances).reduce(
    (acc: Record<CurrencyDto['name'], AccountTotalBalance>, [currencyName, balance]) => {
      acc[currencyName] = balance.fiatEquivalents;
      return acc;
    },
    {},
  );
};

const computeAccountTotalBalancesValue = (accountTotalBalancesValues: Record<string, AccountTotalBalance>) => {
  const accountTotalBalancesValuesSum = {} as AccountTotalBalance;

  Object.values(accountTotalBalancesValues).forEach((accountTotalBalancesValues) => {
    Object.entries(accountTotalBalancesValues).forEach((accountTotalBalancesValue) => {
      const selectableCurrency = accountTotalBalancesValue[0] as SelectableCurrency;
      const value = accountTotalBalancesValue[1];

      if (accountTotalBalancesValuesSum[selectableCurrency]) {
        accountTotalBalancesValuesSum[selectableCurrency] = BigNumber(accountTotalBalancesValuesSum[selectableCurrency])
          .plus(value)
          .toNumber();
      } else {
        accountTotalBalancesValuesSum[selectableCurrency] = value;
      }
    });
  });

  return accountTotalBalancesValuesSum;
};

const selectAccountBalancesTotalValues = createSelector(
  selectAccountsData,
  selectSelectedAccountId,
  (accounts, selectedAccountId) => {
    const accountBalances = accounts?.find(({ id }) => {
      return selectedAccountId ? id === selectedAccountId : true;
    })?.balances;
    return accountBalances ? computeAccountTotalBalancesValues(accountBalances) : {};
  },
);

export const selectAccountsTotalValues = createSelector(selectAccountsData, (accounts) => {
  if (!accounts) return {};

  return accounts.reduce((acc: Record<number, AccountTotalBalance>, account) => {
    const accountId = account.id as number;
    const accountBalances = account.balances;
    const accountTotalBalancesValues = accountBalances ? computeAccountTotalBalancesValues(accountBalances) : {};

    acc[accountId] = computeAccountTotalBalancesValue(accountTotalBalancesValues);
    return acc;
  }, {});
});

export const selectAccountsTotalValue = createSelector(
  selectAccountsTotalValues,
  (accountsTotalValues): AccountTotalBalance => {
    return computeAccountTotalBalancesValue(accountsTotalValues);
  },
);

export const selectAccountTotalValue = createSelector(
  selectSelectedAccountId,
  selectAccountsTotalValue,
  selectAccountsTotalValues,
  (selectedAccountId, accountsTotalValue, accountsTotalValues): AccountTotalBalance => {
    return selectedAccountId === null ? accountsTotalValue : accountsTotalValues[selectedAccountId];
  },
);
//endregion

//region Account balances selector factory
const createAccountBalancesSelector = (
  accountIdSelector: MemoizedSelector<object, number | null>,
  onlyAvailable?: boolean,
) =>
  createSelector(
    accountIdSelector,
    selectAccountsData,
    queryCurrencies(),
    (accountId, accounts, allAvailableCurrencies): Record<string, CurrencyBalanceWithEquivalentsDto> => {
      if (!accounts) {
        return {};
      }

      if (accountId === null) {
        return accounts.reduce((acc: Record<string, CurrencyBalanceWithEquivalentsDto>, account) => {
          if (!account?.balances) {
            return acc;
          }

          const accountBalances = Object.fromEntries(
            Object.entries(account.balances).filter(balancesFilter(allAvailableCurrencies, onlyAvailable)),
          ) as unknown as Record<string, CurrencyBalanceWithEquivalentsDto>;

          if (accountBalances) {
            for (const currencyName in accountBalances) {
              const currencyBalanceWithEquivalents = accountBalances[currencyName];

              for (const currencyBalanceWithEquivalentsKey in currencyBalanceWithEquivalents) {
                const key = currencyBalanceWithEquivalentsKey as keyof CurrencyBalanceWithEquivalentsDto;
                acc[currencyName] = acc[currencyName] || {};

                if (key === 'fiatEquivalents') {
                  const previousValues = acc[currencyName][key] || {};
                  const values = currencyBalanceWithEquivalents[key];
                  acc[currencyName][key] = Object.entries(values).reduce(
                    (acc: Record<string, number>, [valueKey, value]) => {
                      const previousValue = previousValues[valueKey] ?? 0;
                      acc[valueKey] = previousValue + value;
                      return acc;
                    },
                    {},
                  );
                } else {
                  const previousValue = acc[currencyName][key] ?? 0;
                  const value = currencyBalanceWithEquivalents[key];
                  acc[currencyName][key] = previousValue + value;
                }
              }
            }
          }
          return acc;
        }, {});
      }

      const account = accounts.find(({ id }) => id === accountId);
      if (account?.balances) {
        return Object.fromEntries(
          Object.entries(account.balances).filter(balancesFilter(allAvailableCurrencies, onlyAvailable)),
        );
      }

      return {};
    },
  );

//endregion

//region Only available balances for active account ID
export const selectOnlyAvailableAccountBalances = createSelector(
  createAccountBalancesSelector(selectSelectedAccountId, true),
  (accountBalances) => accountBalances,
);
//endregion

//region Account value history total
export const selectValueHistoryTotal = createSelector(selectPortfolioState, (state) => state.valueHistoryTotal.data);

export const selectValueHistoryTotalStatus = createSelector(
  selectPortfolioState,
  (state) => state.valueHistoryTotal.status,
);
export const selectValueHistoryTotalError = createSelector(
  selectPortfolioState,
  (state) => state.valueHistoryTotal.error,
);
//endregion

//region Account value histories
export const selectAccountsValueHistory = createSelector(
  selectPortfolioState,
  (state) => state.accountsValueHistory.data,
);

export const selectAccountsValueHistoryStatus = createSelector(
  selectPortfolioState,
  (state) => state.accountsValueHistory.status,
);
export const selectAccountsValueHistoryError = createSelector(
  selectPortfolioState,
  (state) => state.accountsValueHistory.error,
);

export const selectCombinedValuesHistoryStatus = createSelector(
  selectValueHistoryTotalStatus,
  selectAccountsValueHistoryStatus,
  (valueHistoryTotalStatus, accountsValueHistoryStatus) => {
    const statuses = [valueHistoryTotalStatus, accountsValueHistoryStatus];
    if (statuses.some((status) => status === ApiStateStatus.loading)) {
      return ApiStateStatus.loading;
    }
    if (statuses.some((status) => status === ApiStateStatus.loading)) {
      return ApiStateStatus.error;
    }
    return ApiStateStatus.pending;
  },
);
//endregion

//region Account value history for active account ID
export const selectAccountValueHistory = createSelector(
  selectValueHistoryTotal,
  selectAccountsValueHistory,
  selectSelectedAccountId,
  (accountsValueHistoriesTotal, accountsValueHistories, selectedAccountId) => {
    const accountValueHistory = selectedAccountId
      ? accountsValueHistories?.find(({ id }) => id === selectedAccountId)?.history
      : accountsValueHistoriesTotal;
    return accountValueHistory
      ? accountValueHistory.reduce((acc: AccountValueHistory[], data: AccountValueHistoryDto) => {
          const { valueCalculatedDate, czkValue, eurValue } = data;
          if (!valueCalculatedDate) {
            return acc;
          }

          acc.push({
            startDay: DateTime.fromISO(valueCalculatedDate, { setZone: true }).startOf('day'),
            endDay: DateTime.fromISO(valueCalculatedDate, { setZone: true }).endOf('day'),
            CZK: czkValue ?? 0,
            EUR: eurValue ?? 0,
          });
          return acc;
        }, [])
      : null;
  },
);

/**
 *
 * @param timePeriod - The time period for which historical account value data should be returned.
 * @param extraInterval - If set to true, adds an additional interval (day, week, or month) before the start of the selected period depending on the chosen `timePeriod`.
 * @param combineTodayDataWithLiveData - If set to true, combines historical data for today with the current live data.
 */
export const selectAccountValueHistoryEvolutionByTimePeriod = (
  timePeriod: TimePeriod,
  extraInterval = false,
  combineTodayDataWithLiveData = true,
) =>
  createSelector(
    selectAccountValueHistory,
    selectAccountTotalValue,
    (accountValueHistory, currentAccountValue): AccountValueHistory[] | null => {
      if (!accountValueHistory?.length || !currentAccountValue) {
        return null;
      }

      const dateUTC = DateTime.utc().endOf('day');
      const timeInterval = getIntervalUnitByTimePeriod(timePeriod);

      const startDayIndex = getTimePeriodDayOffset(timePeriod);

      let intervalStart = dateUTC.minus({ day: startDayIndex }).endOf(timeInterval);
      const intervalEnd = DateTime.utc().endOf(timeInterval);

      if (extraInterval) {
        intervalStart = intervalStart.minus({ [timeInterval]: 1 });
      }

      const intervals = Interval.fromDateTimes(intervalStart, intervalEnd);

      if (!intervals?.start || !intervals?.end) {
        return null;
      }

      const accountValueHistoryFromTo = accountValueHistory.filter(
        (data) => data.startDay >= (intervals.start as DateTime) && data.endDay <= (intervals.end as DateTime),
      );

      if (combineTodayDataWithLiveData) {
        // No history is available for today's data, so the current portfolio value is used for today
        // BTC value is not updated!

        const currentDateTime = DateTime.local();
        const lastDefinedData = accountValueHistory[accountValueHistory.length - 1];
        const todayData = {
          CZK: currentAccountValue.CZK,
          EUR: currentAccountValue.EUR,
        } as AccountValueHistory;

        if (currentDateTime.endOf('day') > lastDefinedData.endDay) {
          todayData['startDay'] = currentDateTime.endOf('day').toUTC().startOf('day');
          todayData['endDay'] = currentDateTime.endOf('day').toUTC().endOf('day');

          accountValueHistoryFromTo.push(todayData);
        } else {
          todayData['startDay'] = lastDefinedData.startDay;
          todayData['endDay'] = lastDefinedData.endDay;

          accountValueHistoryFromTo.splice(-1, 1, todayData);
        }
      }

      return accountValueHistoryFromTo;
    },
  );

export const selectAccountValueHistoryByTimePeriod = (timePeriod: TimePeriod) =>
  createSelector(
    selectAccountValueHistoryEvolutionByTimePeriod(timePeriod),
    (accountValueHistory): AccountValueHistoryByTimePeriod | null => {
      if (!accountValueHistory) return null;

      const startDateData = accountValueHistory[0];
      const endDateData = accountValueHistory[accountValueHistory.length - 1];

      const dateDiff = getDateDiff(startDateData, endDateData);

      return {
        ...dateDiff,
        startDay: startDateData.startDay,
        endDay: endDateData.startDay,
      };
    },
  );
//endregion

//region Account value history for active account ID
const selectAccountsDepositsWithdrawalsHistory_DUMMY_DATA = createSelector(selectAccountsData, (accountsData) => {
  let now = new Date();
  now = new Date(now.setDate(now.getDate()) - 180);
  return accountsData?.map(({ id }) => {
    let value = 0;
    return {
      id,
      history: [...Array(180).keys()]
        .map((day) => {
          value = day % 4 === 0 ? Math.floor(Math.random() * 3000000001) : value;
          const valueCalculatedDate = DateTime.utc().minus({ day });
          return {
            startDay: valueCalculatedDate.startOf('day'),
            endDay: valueCalculatedDate.endOf('day'),
            CZK: value,
            EUR: value,
          };
        })
        .reverse(),
    };
  });
});

export const selectAccountDepositsWithdrawalsHistory = createSelector(
  selectAccountsDepositsWithdrawalsHistory_DUMMY_DATA,
  selectSelectedAccountId,
  (accountsDepositsWithdrawalsHistories, selectedAccountId) => {
    const accountDepositsWithdrawalsHistory = selectedAccountId
      ? accountsDepositsWithdrawalsHistories?.find(({ id }) => id === selectedAccountId)?.history
      : accountsDepositsWithdrawalsHistories?.[0].history;

    return accountDepositsWithdrawalsHistory || null;
  },
);

export const selectAccountDepositsWithdrawalsHistoryEvolutionByTimePeriod = (timePeriod: TimePeriod) =>
  createSelector(
    selectAccountDepositsWithdrawalsHistory,
    (accountDepositsWithdrawalsHistory): AccountValueHistory[] | null => {
      if (!accountDepositsWithdrawalsHistory) return null;

      const startDayIndex = getTimePeriodDayOffset(timePeriod);

      return accountDepositsWithdrawalsHistory.slice(-startDayIndex);
    },
  );
//endregion

//region Account value changes history for active account ID
export const selectAccountValueChangesHistoryEvolutionByTimePeriod = (timePeriod: TimePeriod) =>
  createSelector(selectAccountValueHistoryEvolutionByTimePeriod(timePeriod, true), (accountValueHistory) => {
    if (!accountValueHistory) {
      return null;
    }

    const firstDefinedData = accountValueHistory[0];
    const lastDefinedData = accountValueHistory[accountValueHistory.length - 1];

    if (!firstDefinedData || !lastDefinedData) {
      return null;
    }

    const timeInterval = getIntervalUnitByTimePeriod(timePeriod);
    const intervals = getIntervals(firstDefinedData.startDay, lastDefinedData.startDay, timeInterval);

    return intervals.reduce((acc: AccountValueHistory[], originalInterval, index, intervals) => {
      let startDateData: AccountValueHistory | undefined = undefined;
      let endDateData: AccountValueHistory | undefined = undefined;
      let interval = originalInterval;

      if (timePeriod === TimePeriod['24hours'] || timePeriod === TimePeriod['7days']) {
        if (index === 0) {
          return acc;
        }

        const previousInterval = intervals[index - 1];
        const previousIntervalStart = previousInterval.start;
        const intervalStart = originalInterval.start;

        if (!previousIntervalStart || !intervalStart) {
          return acc;
        }

        startDateData = previousIntervalStart
          ? accountValueHistory.find((item) => item.startDay.equals(previousIntervalStart))
          : undefined;
        endDateData = intervalStart
          ? accountValueHistory.find((item) => item.startDay.equals(intervalStart))
          : undefined;

        if (!startDateData?.startDay || !endDateData?.startDay) {
          return acc;
        }

        interval = originalInterval.set({
          start: startDateData.startDay,
          end: endDateData.startDay,
        });
      } else {
        const intervalStart = originalInterval.start;
        const intervalEnd = originalInterval.end;

        if (!intervalStart || !intervalEnd) {
          return acc;
        }

        const overMin = intervalStart <= firstDefinedData.startDay;
        const overMax = intervalEnd >= lastDefinedData.endDay;

        if (timePeriod === TimePeriod['30days'] && overMax) {
          return acc;
        }

        startDateData = overMin
          ? firstDefinedData
          : accountValueHistory.find((item) => item.startDay.equals(intervalStart));
        endDateData = overMax ? lastDefinedData : accountValueHistory.find((item) => item.endDay.equals(intervalEnd));

        if (!startDateData?.startDay || !endDateData?.startDay) {
          return acc;
        }

        interval = originalInterval.set({
          start: startDateData.startDay,
          end: overMax ? endDateData.startDay : endDateData.endDay,
        });
      }

      const dateDiff = getDateDiff(startDateData, endDateData);

      acc.push({
        startDay: interval.start as DateTime,
        endDay: interval.end as DateTime,
        CZK: dateDiff.CZK.changePercentage,
        EUR: dateDiff.EUR.changePercentage,
      });

      return acc;
    }, []);
  });
//endregion

//region Favourites
export const selectAllFavorites = createSelector(selectPortfolioState, (state) => state.favorites.data || []);

export const selectAllFavoritesStatus = createSelector(selectPortfolioState, (state) => state.favorites.status);
export const selectAllFavoritesError = createSelector(selectPortfolioState, (state) => state.favorites.error);
//endregion

//region Account settings
export const selectAccountSettingsData = createSelector(selectPortfolioState, (state) => state.accountSettings.data);

export const selectAccountSettingsStatus = createSelector(
  selectPortfolioState,
  (state) => state.accountSettings.status,
);
export const selectAccountSettingsError = createSelector(selectPortfolioState, (state) => state.accountSettings.error);
//endregion

//region Enriched balances by currency equivalents for specific account ID
export const selectAccountBalancesWithCurrencyEquivalentsByAccountId = (accountId: number) =>
  createSelector(
    createBalancesWithCurrencyEquivalentsSelector(
      createAccountBalancesSelector(createAccountIdFromParamSelector(accountId)),
    ),
    selectAllFavorites,
    (currencyBalancesWithEquivalents, favourites): BalanceWithCurrencyEquivalents[] => currencyBalancesWithEquivalents,
  );
//endregion

//region Combined balances selector factory
const createAccountCombinedBalancesSelector = (
  accountBalancesSelector: MemoizedSelector<object, Record<string, CurrencyBalanceWithEquivalentsDto>>,
) =>
  createSelector(
    createBalancesWithCurrencyEquivalentsSelector(accountBalancesSelector),
    selectAllFavorites,
    (balancesPaired, favourites): CurrencyWithBalance[] | null => {
      return balancesPaired.map(
        (combinedAvailableBalance): CurrencyWithBalance => ({
          ...combinedAvailableBalance,
          favorite: favourites.includes(combinedAvailableBalance.name),
        }),
      );
    },
  );
//endregion

//region Combined balances for active account ID
export const selectAccountCombinedBalances = createSelector(
  createAccountCombinedBalancesSelector(createAccountBalancesSelector(selectSelectedAccountId)),
  (combinedAvailableBalances) => combinedAvailableBalances,
);
//endregion

//region Combined balances for specific account ID
export const selectAccountCombinedBalancesByAccountId = (accountId: number) =>
  createSelector(
    createAccountCombinedBalancesSelector(createAccountBalancesSelector(createAccountIdFromParamSelector(accountId))),
    (combinedAvailableBalances) => combinedAvailableBalances,
  );
//endregion

// region Combined balances for specific account ID - only available balances
export const selectAccountCombinedAvailableBalancesByAccountId = (accountId: number) =>
  createSelector(
    createAccountCombinedBalancesSelector(
      createAccountBalancesSelector(createAccountIdFromParamSelector(accountId), true),
    ),
    (combinedAvailableBalances) => combinedAvailableBalances,
  );
// endregion
